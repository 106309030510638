import PropTypes from 'prop-types';
import React from 'react';
import WavyHero from './wavy-hero';
import StyledParagraph from './common/styled-paragraph';
import StyledTitle from './common/styled-title';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Header = ({ alternate, headerTitle, headerText }) => (
  <StaticQuery
    query={graphql`
      query {
        avatarImage: file(relativePath: { eq: "headshot.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 72, height: 72) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => (
      <section
        style={{
          marginBottom: `4.45rem`,
        }}
      >
        <WavyHero>
          <StyledTitle invert={alternate}>
            <Img fixed={data.avatarImage.childImageSharp.fixed} />
            {headerTitle}
          </StyledTitle>
          <StyledParagraph style={{ fontSize: '1rem' }}>
            {headerText}
          </StyledParagraph>
        </WavyHero>
      </section>
    )}
  />
);

Header.propTypes = {
  alternate: PropTypes.bool,
  headerTitle: PropTypes.string,
  headerText: PropTypes.string,
};

Header.defaultProps = {
  alternate: false,
  headerTitle: "Hi! I'm Babs.",
  headerText:
    "I'm a JavaScript Developer  💻 with experience working across the stack, totally in love with the buzz that comes from building and shipping products. Did I mention that I ❤️ JavaScript? React & React Native and Node are my current JS flavours of choice. Take a look around and do let me know if you need anything! ☕",
};

export default Header;
