import styled from "styled-components";

const StyledTitle = styled.h1`
  color: "white";
  position: relative;

  .gatsby-image-wrapper {
    position: absolute !important;
    right: 0;

    img {
      display: block;
      border-radius: 50%;
      border: 3px white solid;
      position: absolute;
      right: 0;
    }
  }

  @media screen and (max-width: 693px) {
    display: flex;
    flex-direction: column;
    
    .gatsby-image-wrapper {
      position: relative !important;
      margin-bottom: 12px;
    }
  }

`;

export default StyledTitle;
