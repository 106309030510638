import React from "react";
import styled from "styled-components";
import COLORS from "../constants/colors";

const WavyHeroEl = styled.div`
  background: ${COLORS.baseColor};

  .wavy-layout {
    margin: 0 auto;
    max-width: 960px;
    padding: 3.45rem 1.0875rem;
    color: white;

    @media screen and (max-width: 693px) {
      padding: 2rem 0.9rem;
    }
  }
`;

const WavyHero = ({ children }) => (
  <WavyHeroEl>
    <div className="wavy-layout">{children}</div>
  </WavyHeroEl>
);

export default WavyHero;
